<template>
  <div id="app1" class="app-container">
    <div class="session1 clearfix">
      <div class="shopBox" ref="shopBox" @click="treeClick">
        <i>组织：</i>
        <input type="text" readonly name="" id="" :value="checkedName" />
        <span class="el-tree-node__expand-icon el-icon-caret-right expanded"></span>
      </div>
      <div class="treeBox" ref="treeBox" v-show="treeIsShow">
        <keep-alive>
          <el-input placeholder="请输入门店名称搜索" v-model="searchOrg" @change="searchOrg" class="input-with-select">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </keep-alive>
        <el-tree
          node-key="orgcode"
          :data="shopsTree"
          :props="defaultProps"
          ref="tree"
          :default-expanded-keys="[orgcode]"
          :filter-node-method="filterNode"
          :expand-on-click-node="false"
          highlight-current="true"
          @node-click="handleNodeClick"
        ></el-tree>
        <div class="dialogBtn pt20 mb20 borderTop">
          <button @click="getOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
          <button @click="hideAll" type="button" class="ag-model-btn gray fts">取消</button>
        </div>
      </div>
      <el-input
        placeholder="请输入摄像头MacID、Camera ID、摄像头名称、盒子MacID、端口号、客户名称、门店名称、ShopID检索"
        v-model="keyword"
        style="width: 750px"
        class="input-with-select ml20"
      >
        <el-button class="searchbtn" slot="append" type="primary" style="background: #5c76cc" @click="overviewInit">
          搜索
        </el-button>
      </el-input>
      <div class="search">
        <div class="row">
          <label>在线状态：</label>
          <el-radio-group v-model="onlineStatus">
            <el-radio :label="null">不限</el-radio>
            <el-radio :label="true">在线</el-radio>
            <el-radio :label="false">离线</el-radio>
          </el-radio-group>
        </div>
        <div class="row">
          <label>码流类型：</label>
          <el-radio-group v-model="streamStatus">
            <el-radio :label="null">不限</el-radio>
            <el-radio label="MAJOR">主码流</el-radio>
            <el-radio label="MINOR">次码流</el-radio>
          </el-radio-group>
        </div>
        <div class="row">
          <label>业务程序：</label>
          <span :class="!business.length ? 'active' : ''" @click="noLimitBusiness">不限</span>
          <el-checkbox-group v-model="business" @change="changeBusiness">
            <el-checkbox v-for="(item, index) in businessList" :key="index" :label="item.value">
              {{ item.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div v-show="ifSpread">
          <div class="row">
            <label>区域配置：</label>
            <span :class="!areaConfig.length ? 'active' : ''" @click="noLimitArea">不限</span>
            <el-checkbox-group v-model="areaConfig" @change="changeAreaConfig">
              <el-checkbox v-for="(item, index) in areaList" :key="index" :label="item.value">
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="row">
            <label>品牌：</label>
            <el-radio-group v-model="brand">
              <el-radio :label="null">不限</el-radio>
              <el-radio label="CUE">开域</el-radio>
              <el-radio label="HIK_VISION">海康</el-radio>
              <el-radio label="DA_HUA">大华</el-radio>
            </el-radio-group>
          </div>
          <div class="row">
            <label>上传视频：</label>
            <el-radio-group v-model="videoStatus">
              <el-radio :label="null">不限</el-radio>
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </div>
          <div class="row">
            <label>网络配置：</label>
            <el-radio-group v-model="networkConfig">
              <el-radio :label="null">不限</el-radio>
              <el-radio label="DHCP">DHCP</el-radio>
              <el-radio label="STATIC">Static</el-radio>
            </el-radio-group>
          </div>
          <div class="row">
            <label>时间设置：</label>
            <el-radio-group v-model="timeConfig">
              <el-radio :label="null">不限</el-radio>
              <el-radio label="MANUAL">手动校时</el-radio>
              <el-radio label="NTP">NTP校时</el-radio>
            </el-radio-group>
          </div>
          <div class="row">
            <label>回放方式：</label>
            <el-radio-group v-model="boxReplay">
              <el-radio :label="null">不限</el-radio>
              <el-radio :label="true">盒子端播放</el-radio>
              <el-radio :label="false">服务器播放</el-radio>
            </el-radio-group>
          </div>
          <div class="row">
            <label>
              <span>客流计算</span>
              <el-tooltip class="item" effect="light" content="参与边缘计算" placement="top-start">
                <i class="el-icon-question"></i>
              </el-tooltip>
              <span>：</span>
            </label>
            <el-radio-group v-model="edgeBox">
              <el-radio :label="undefined">不限</el-radio>
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="divider">
        <span v-if="!ifSpread" @click="spreadSearch">
          更多
          <i class="el-icon-arrow-down"></i>
        </span>
        <span v-else @click="spreadSearch">
          收起
          <i class="el-icon-arrow-up"></i>
        </span>
      </div>
      <div class="flex alignCenter flex-between">
        <div class="flex alignCenter">
          <el-button type="primary" @click="overviewInit">查询</el-button>
          <span class="reset ml30" @click="resetSearch">清空条件</span>
        </div>
        <div style="position: relative" ref="customDialog">
          <a class="downloadstyle bord" :href="downloadurl">
            <i class="fa fa-download fhd"></i>
          </a>
          <button class="ag-grey-button bord ml10" v-on:click="setTrue()">
            <i class="fa fa-gear thd"></i>
          </button>
          <div id="custom-column-wrap" class="ag-custom-column bor fts" v-show="dialogIsShow" style="display: none">
            <div class="ag-layout-block">
              <span style="font-weight: bold; font-size: 14px">自定义设置列</span>
              <a href="javascript:;" @click="setDefult" id="reset-column" class="fr" style="font-size: 14px">
                恢复默认
              </a>
            </div>
            <div class="ag-layout-block hori ag-check-group">
              <div class="section">
                <section>
                  <el-checkbox
                    class="title"
                    :indeterminate="isIndeterminateBaseInfo"
                    v-model="checkAllBaseInfo"
                    @change="handleCheckAllBaseInfoChange"
                  >
                    基本信息
                  </el-checkbox>
                  <el-checkbox-group v-model="checkedBaseInfo" @change="handleBaseInfoChange">
                    <el-checkbox
                      :disabled="item.key === 'mac'"
                      v-for="(item, index) in baseInfoData"
                      :label="item.key"
                      :key="index"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </section>
                <section>
                  <el-checkbox
                    class="title"
                    :indeterminate="isIndeterminateInstallInfo"
                    v-model="checkAllInstallInfo"
                    @change="handleCheckAllInstallInfoChange"
                  >
                    安装信息
                  </el-checkbox>
                  <el-checkbox-group v-model="checkedInstallInfo" @change="handleInstallInfoChange">
                    <el-checkbox v-for="(item, index) in installInfoData" :label="item.key" :key="index">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </section>
                <section>
                  <el-checkbox
                    class="title"
                    :indeterminate="isIndeterminateHardwareInfo"
                    v-model="checkAllHardwareInfo"
                    @change="handleCheckAllHardwareInfoChange"
                  >
                    硬件信息
                  </el-checkbox>
                  <el-checkbox-group v-model="checkedHardwareInfo" @change="handleHardwareInfoChange">
                    <el-checkbox v-for="(item, index) in hardwareInfoData" :label="item.key" :key="index">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </section>
                <section>
                  <el-checkbox
                    class="title"
                    :indeterminate="isIndeterminateWorkInfo"
                    v-model="checkAllWorkInfo"
                    @change="handleCheckAllWorkInfoChange"
                  >
                    运行信息
                  </el-checkbox>
                  <el-checkbox-group v-model="checkedWorkInfo" @change="handleWorkInfoChange">
                    <el-checkbox
                      :disabled="item.key === 'dataRate'"
                      v-for="(item, index) in workInfoData"
                      :label="item.key"
                      :key="index"
                    >
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </section>
                <section>
                  <el-checkbox
                    class="title"
                    :indeterminate="isIndeterminateCameraParams"
                    v-model="checkAllCameraParams"
                    @change="handleCheckAllCameraParamsChange"
                  >
                    摄像头参数
                  </el-checkbox>
                  <el-checkbox-group v-model="checkedCameraParams" @change="handleCameraParamsChange">
                    <el-checkbox v-for="(item, index) in cameraParamsData" :label="item.key" :key="index">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </section>
                <section>
                  <el-checkbox
                    class="title"
                    :indeterminate="isIndeterminateCameraConfig"
                    v-model="checkAllCameraConfig"
                    @change="handleCheckAllCameraConfigChange"
                  >
                    主码流配置
                  </el-checkbox>
                  <el-checkbox-group v-model="checkedCameraConfig" @change="handleCameraConfigChange">
                    <el-checkbox v-for="(item, index) in cameraConfigData" :label="item.key" :key="index">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </section>
                <section>
                  <el-checkbox
                    class="title"
                    :indeterminate="isIndeterminateStreamConfig"
                    v-model="checkAllStreamConfig"
                    @change="handleCheckAllStreamConfigChange"
                  >
                    次码流配置
                  </el-checkbox>
                  <el-checkbox-group v-model="checkedStreamConfig" @change="handleStreamConfigChange">
                    <el-checkbox v-for="(item, index) in streamConfigData" :label="item.key" :key="index">
                      {{ item.label }}
                    </el-checkbox>
                  </el-checkbox-group>
                </section>
              </div>
            </div>
            <div class="ag-layout-block top">
              <button @click="getTableList" type="button" class="ag-model-btn blue fts mr5">保存</button>
              <button @click="dialogCancel" type="button" class="ag-model-btn gray fts">取消</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="integratable">
      <el-table :data="useTable" v-loading="loading" @sort-change="sortChange" row-key="mac" style="width: 100%">
        <el-table-column
          v-for="(item, index) in tableColumnData"
          :key="index"
          :label="item.label"
          :prop="item.key"
          :width="item.width"
          :sortable="item.sortable"
        >
          <template slot-scope="scope">
            <div v-if="item.key === 'shopId'">
              <el-tooltip :content="scope.row.shopId" placement="top">
                <span>
                  {{ scope.row.shopId.slice(0, 10) + '...' }}
                  <i
                    class="fa fa-files-o"
                    @click="copyToClipboard(scope.row.shopId)"
                    style="cursor: pointer; color: #409eff"
                  ></i>
                </span>
              </el-tooltip>
            </div>
            <div v-else-if="item.key === 'installHeight'">
              <span v-show="scope.row.installHeight">{{ scope.row.installHeight }}m</span>
            </div>
            <div v-else-if="item.key === 'installAngle'">
              <span v-show="scope.row.installAngle">{{ scope.row.installAngle }}°</span>
            </div>
            <div v-else-if="item.key === 'onlineStatus'">
              <span :class="[scope.row.onlineStatus === '离线' ? 'offline' : '']">{{ scope.row.onlineStatus }}</span>
            </div>
            <div v-else-if="item.key === 'wareHousingTime'">
              {{ formatTime(scope.row.wareHousingTime) }}
            </div>
            <div v-else-if="item.key === 'bindTime'">
              {{ formatTime(scope.row.bindTime) }}
            </div>
            <div v-else-if="item.key === 'lastHeartBeatTime'">
              {{ formatTime(scope.row.lastHeartBeatTime) }}
            </div>
            <div v-else-if="item.key === 'runningProcess'">
              {{ scope.row.runningProcess ? scope.row.runningProcess.join() : '' }}
            </div>
            <div v-else-if="item.key === 'areaConfig'">
              {{ scope.row.areaConfig ? scope.row.areaConfig.join() : '' }}
            </div>
            <div v-else-if="item.key === 'resolution'">
              {{ scope.row.majorCamVideoConfig.resolution }}
            </div>
            <div v-else-if="item.key === 'videoQuality'">
              {{ scope.row.majorCamVideoConfig.videoQuality }}
            </div>
            <div v-else-if="item.key === 'encode'">
              {{ scope.row.majorCamVideoConfig.encode }}
            </div>
            <div v-else-if="item.key === 'keyFrameInterval'">
              {{ scope.row.majorCamVideoConfig.keyFrameInterval }}
            </div>
            <div v-else-if="item.key === 'frameRate'">
              {{ scope.row.majorCamVideoConfig.frameRate }}
            </div>
            <div v-else-if="item.key === 'dataRateUpperLimit'">
              {{ scope.row.majorCamVideoConfig.dataRateUpperLimit }}
            </div>
            <div v-else-if="item.key === 'fpsType'">
              {{ scope.row.majorCamVideoConfig.fpsType }}
            </div>
            <div v-else-if="item.key === 'resolution2'">
              {{ scope.row.minorCamVideoConfig.resolution }}
            </div>
            <div v-else-if="item.key === 'videoQuality2'">
              {{ scope.row.minorCamVideoConfig.videoQuality }}
            </div>
            <div v-else-if="item.key === 'encode2'">
              {{ scope.row.minorCamVideoConfig.encode }}
            </div>
            <div v-else-if="item.key === 'keyFrameInterval2'">
              {{ scope.row.minorCamVideoConfig.keyFrameInterval }}
            </div>
            <div v-else-if="item.key === 'frameRate2'">
              {{ scope.row.minorCamVideoConfig.frameRate }}
            </div>
            <div v-else-if="item.key === 'dataRateUpperLimit2'">
              {{ scope.row.minorCamVideoConfig.dataRateUpperLimit }}
            </div>
            <div v-else-if="item.key === 'fpsType2'">
              {{ scope.row.minorCamVideoConfig.fpsType }}
            </div>
            <div v-else-if="item.key === 'boxReplay'">
              {{ scope.row.boxReplay ? '盒子端播放' : '服务器播放' }}
            </div>
            <div v-else>{{ scope.row[item.key] }}</div>
          </template>
        </el-table-column>
        <el-table-column label="底图" prop="snapshotPath" width="100">
          <template slot-scope="scope">
            <img
              :src="scope.row.snapshotPath"
              width="80"
              style="cursor: pointer"
              @click="handleImgDialog(scope.row.snapshotPath)"
            />
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="200">
          <template slot-scope="scope">
            <el-button @click="detail(scope.row)" type="text" size="small">查看</el-button>
            <el-button @click="golive(scope.row, scope.$index)" type="text" size="small">直播</el-button>
            <el-button type="text" size="small" @click="toDeviceInfo(scope.row)">设备信息查询</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flexend">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 30, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next"
        :total="tableData.length"
      ></el-pagination>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img :src="imgPath" width="100%" />
    </el-dialog>
  </div>
</template>

<script>
import treemixin from 'src/mixins/tree'
import tmpColumn from 'src/mixins/templateColumn'

import { getMngList, getonlinestatus } from 'src/api/legacy'

var storeHttp = '/newretail'

export default {
  mixins: [treemixin, tmpColumn],
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      orgcode: '0',
      prop: 'togrouptime',
      order: '',
      activeIndex: 0,
      camersStatus: 0,
      keyword: '',
      copycontent: '',
      tableData: [],
      ifSpread: false,
      onlineStatus: null,
      streamStatus: null,
      videoStatus: null,
      networkConfig: null,
      timeConfig: null,
      boxReplay: null,
      brand: null,
      edgeBox: undefined,
      businessList: [
        {
          label: 'Rid进出店',
          value: 'RID',
        },
        {
          label: '区域进出店',
          value: 'AREA_IN_OUT_SHOP',
        },
        {
          label: '店前客流',
          value: 'PASS_BY',
        },
        {
          label: '驻足客流',
          value: 'STAY_CUSTOMER_FLOW',
        },
        {
          label: '热力图',
          value: 'HEAT_MAP',
        },
        {
          label: '客流流向',
          value: 'CUSTOMER_FLOW_DIR',
        },
        {
          label: '夜间防盗',
          value: 'NIGHT_ANTI_THEFT',
        },
        {
          label: '收银事件',
          value: 'CASHIER_EVENT',
        },
        {
          label: '进出店店员排除',
          value: 'CLERK_DEDUPLICATION',
        },
        {
          label: '全店店员排除',
          value: 'EXCLUDE_STAFF',
        },
        {
          label: '小孩去重',
          value: 'CHILD_DEDUPLICATION',
        },
        {
          label: '平均停留时长',
          value: 'AVG_STAY_DURATION',
        },
      ],
      business: [],
      areaConfig: [],
      areaList: [
        {
          label: '进店线',
          value: 'ENTER_SHOP_ROUTE',
        },
        {
          label: '进出店区域',
          value: 'IN_OUT_AREA',
        },
        {
          label: 'Passby区域',
          value: 'PASSBY_AREA',
        },
        {
          label: '驻足区域',
          value: 'STAY_AREA',
        },
        {
          label: '热力区域',
          value: 'HOT_AREA',
        },
        {
          label: '流向区域',
          value: 'FLOW_AREA',
        },
        {
          label: '监控防盗区域',
          value: 'MONITOR_AND_ANTI_THEFT',
        },
        {
          label: '收银区域',
          value: 'CASHIER_AREA',
        },
        {
          label: '店内排重区',
          value: 'INNER_SHOP_DEDUP_AREA',
        },
      ],
      dialogVisible: false,
      imgPath: '',
      loading: false,
    }
  },
  methods: {
    // 展开搜索条件
    spreadSearch() {
      this.ifSpread = !this.ifSpread
    },
    // 重置搜索条件
    resetSearch() {
      this.checkedName = 'ROOT'
      this.orgcode = '0'
      this.keyword = ''
      this.onlineStatus = null
      this.streamStatus = null
      this.videoStatus = null
      this.networkConfig = null
      this.timeConfig = null
      this.brand = null
      this.boxReplay = null
      this.edgeBox = undefined
      this.business = []
      this.areaConfig = []
      this.$refs.tree.setCurrentKey(this.orgcode)
      this.getMngList()
    },
    // 底图弹框
    handleImgDialog(imgSrc) {
      this.dialogVisible = true
      this.imgPath = imgSrc
    },
    // 格式化时间
    formatTime(time) {
      if (!time) return
      const val = new Date(time)
      return (
        val.getFullYear() +
        '-' +
        (val.getMonth() + 1 > 9 ? val.getMonth() + 1 : '0' + (val.getMonth() + 1)) +
        '-' +
        (val.getDate() > 9 ? val.getDate() : '0' + val.getDate()) +
        ' ' +
        (val.getHours() > 9 ? val.getHours() : '0' + val.getHours()) +
        ':' +
        (val.getMinutes() > 9 ? val.getMinutes() : '0' + val.getMinutes()) +
        ':' +
        (val.getSeconds() > 9 ? val.getSeconds() : '0' + val.getSeconds())
      )
    },
    copyToClipboard(txt) {
      const tag = document.createElement('input')
      tag.setAttribute('id', 'cp_input')
      tag.value = txt
      tag.style.opacity = '0'
      document.body.appendChild(tag)
      tag.select()
      document.execCommand('copy')
      tag.remove()
      this.$message({
        message: '复制成功',
        type: 'success',
        showClose: true,
      })
    },
    noLimitBusiness() {
      this.business = []
    },
    changeBusiness(val) {},
    noLimitArea() {
      this.areaConfig = []
    },
    changeAreaConfig() {},
    overviewInit() {
      //页面初始化
      this.getMngList()
    },
    detail(row) {
      //查看
      // window.location.href = './cameraDetail/index.html?cameraid=' + row.cameraid
      localStorage.setItem('cameraInfo', JSON.stringify(row))
      this.$router.push({ name: 'camera-query-check' })
    },
    setCamera(row) {
      //设置
      window.location.href = './set/set.html?cameraid=' + row.cameraid
    },
    golive(row) {
      //直播
      this.$router.push({
        name: 'camera-query-live',
        query: {
          cameraid: row.cameraId,
        },
      })
    },
    getMngList() {
      //查询列表
      let data = {
        orgCode: this.orgcode,
        keyword: this.keyword,
        online: this.onlineStatus,
        camDataRate: this.streamStatus,
        camBusinessAppList: this.business.length ? this.business.join() : null,
        camAreaConfigList: this.areaConfig.length ? this.areaConfig.join() : null,
        uploadVideo: this.videoStatus,
        camNetConfig: this.networkConfig,
        camTimeConfig: this.timeConfig,
        boxReplay: this.boxReplay,
        camBrand: this.brand,
        edgeBox: this.edgeBox,
      }
      this.loading = true

      getMngList(data)
        .then((res) => {
          if (res && res.status === 0) {
            this.tableData = res.data
            for (const v of this.tableData) {
              v.boxPort = v.boxPort ? Number(v.boxPort) : v.boxPort
              v.majorCamVideoConfig.frameRate = v.majorCamVideoConfig.frameRate
                ? Number(v.majorCamVideoConfig.frameRate)
                : v.majorCamVideoConfig.frameRate
              v.minorCamVideoConfig.frameRate = v.minorCamVideoConfig.frameRate
                ? Number(v.minorCamVideoConfig.frameRate)
                : v.minorCamVideoConfig.frameRate
            }
            this.currentPage = 1
            this.loading = false
          }
        })
        .catch(() => {
          this.tableData = []
          this.loading = false
        })
    },
    getStatus() {
      const cameraids = []
      this.tableData.map((e) => {
        cameraids.push(e.cameraid)
      })
      let param = {
        cameraids: cameraids,
      }
      getonlinestatus(param).then((res) => {
        if (res && res.status === 0) {
          this.tableData.forEach((e) => {
            this.$set(e, 'streamstatus', res.data[e.cameraid])
          })
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
      // this.getStatus();
    },
    sortChange(column) {
      const { prop, order } = column
      this.prop = prop
      this.order = order
    },
    sorter(a, b) {
      let aKey = ''
      let bKey = ''
      const order = this.order === 'ascending' ? 1 : -1
      // const reg1 = /[a-zA-Z]/
      // const reg2 = /[\u4e00-\u9fa5]/
      // const aKey = reg1.test(a[this.prop]) || reg2.test(a[this.prop]) ? a[this.prop] || 0 : Number(a[this.prop])
      // const bKey = reg1.test(b[this.prop]) || reg2.test(b[this.prop]) ? b[this.prop] || 0 : Number(b[this.prop])
      const majorCamVideoConfig = this.cameraConfigData.map((item) => item.key)
      const minorCamVideoConfig = this.streamConfigData.map((item) => item.key)
      if (majorCamVideoConfig.includes(this.prop)) {
        aKey = a.majorCamVideoConfig[this.prop] || ''
        bKey = b.majorCamVideoConfig[this.prop] || ''
      } else if (minorCamVideoConfig.includes(this.prop)) {
        aKey = a.minorCamVideoConfig[this.prop.slice(0, -1)] || ''
        bKey = b.minorCamVideoConfig[this.prop.slice(0, -1)] || ''
      } else {
        aKey = a[this.prop] || ''
        bKey = b[this.prop] || ''
      }
      return aKey > bKey ? order : -order
    },
    // 跳转到设备信息页
    toDeviceInfo(row) {
      let href = process.env.VUE_APP_DEVICE_APP
      const username = JSON.parse(localStorage.getItem('user'))[0].username
      const accessToken = localStorage.getItem('accessToken')
      window.open(
        href + '?accessToken=' + accessToken + '&username=' + username + '&cameraId=' + row.cameraId + '&boxType=0'
      )
    },
  },
  watch: {},
  computed: {
    useTable() {
      if (this.order) {
        return this.tableData
          .slice()
          .sort(this.sorter)
          .slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
      } else {
        return this.tableData.slice().slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
      }
    },
    downloadurl() {
      return `${storeHttp}/v2/api/camera/getmnglist/export?orgCode=${this.orgcode}&online=${
        this.onlineStatus || ''
      }&keyword=${this.keyword}&camDataRate=${
        this.streamStatus || ''
      }&camBusinessAppList=${this.business.join()}&camAreaConfigList=${this.areaConfig.join()}&uploadVideo=${
        this.videoStatus || ''
      }&camNetConfig=${this.networkConfig || ''}&camTimeConfig=${this.timeConfig || ''}&camBrand=${
        this.brand || ''
      }&boxReplay=${this.boxReplay === null ? '' : this.boxReplay}&edgeBox=${
        this.edgeBox === undefined ? '' : this.edgeBox?.toString()
      }`
    },
  },
  created() {},
  mounted() {
    let CameraUse = JSON.parse(sessionStorage.getItem('CameraUse'))
    this.loading = true
    this.getTreeData().then(() => {
      if (CameraUse === null) {
        //缓存
        console.log('没有缓存')
      } else {
        // this.activeIndex = CameraUse.active;
        this.camersStatus = CameraUse.camersStatus
        this.orgcode = CameraUse.code !== '' ? CameraUse.code : this.orgcode
        this.keyword = CameraUse.keyword
        CameraUse.treeName && (this.checkedName = CameraUse.treeName)
        this.onlineStatus = CameraUse.online
        this.streamStatus = CameraUse.camDataRate
        this.videoStatus = CameraUse.uploadVideo
        this.networkConfig = CameraUse.camNetConfig
        this.timeConfig = CameraUse.camTimeConfig
        this.brand = CameraUse.camBrand
        this.business = CameraUse.camBusinessAppList || []
        this.areaConfig = CameraUse.camAreaConfigList || []
        this.tableColumnData = CameraUse.tableColumnData
        this.boxReplay = CameraUse.boxReplay
      }
      this.getTableList()
      this.$refs.tree.setCurrentKey(this.orgcode)
    })
    window.onbeforeunload = (e) => {
      //缓存
      let obj = {
        // active: this.activeIndex,
        code: this.orgcode,
        keyword: this.keyword,
        treeName: this.checkedName,
        online: this.onlineStatus,
        camDataRate: this.streamStatus,
        uploadVideo: this.videoStatus,
        camNetConfig: this.networkConfig,
        camTimeConfig: this.timeConfig,
        boxReplay: this.boxReplay,
        camBrand: this.brand,
        camBusinessAppList: this.business,
        camAreaConfigList: this.areaConfig,
        tableColumnData: this.tableColumnData,
      }
      sessionStorage.setItem('CameraUse', JSON.stringify(obj))
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/variables.less';

* {
  box-sizing: border-box;
}
.menu {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
}
.el-button.el-button--default.primary {
  border: 1px solid #5c76cc;
  background-color: #5c76cc;
  color: #fff;
}
.menu .el-button + .el-button {
  margin-left: -4px;
}
.shopBox {
  display: inline-block;
}
.session1 {
  padding: 0px 20px 0 20px;
  margin: 0 -20px;
  align-items: center;
}

.el-tree {
  max-height: 330px;
  overflow-y: auto;
}

.session4 {
  margin-top: 40px;
  width: 100%;
}
.flexend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}
.popupStyle {
  width: 420px;
  position: absolute;
  top: 33px;
  left: -298px;
  background-color: #ffffff;
  border-radius: 2px;
  z-index: 99;
}
.chooseProp {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.trend-layout-block {
  position: relative;
  padding: 15px;
}
.trend-layout-block label input {
  margin-right: 7px;
}
.popupStyle label {
  width: 128px;
  margin-bottom: 15px;
}
.downloadstyle {
  width: 61px;
  height: 30px;
  position: relative;
  bottom: -1px;
  line-height: 27px;
  border-radius: 2px;
  text-align: center;
  color: #000;
  font-size: 14px;
  background-color: #f4f5f7;
  z-index: 1;
}

.el-select {
  width: 194px;
}
.add-indent label {
  width: 121px;
  height: 16px;
  overflow: hidden;
}

/* 设置自定义列 */
.el-checkbox + .el-checkbox {
  margin-left: 0;
}
.el-checkbox .el-checkbox {
  margin-right: 30px;
}
.ag-custom-column label {
  width: 126px;
  margin-bottom: 14px;
}
.ag-custom-column {
  width: 420px;
  position: absolute;
  top: 42px;
  right: 20px;
  background-color: #ffffff;
  border-radius: 2px;
  z-index: 99;
}
.bor {
  border: 1px solid #d2d2d2;
}
.fts {
  font-size: 12px;
}
.ag-layout-block {
  position: relative;
  padding: 15px;
}
.ag-layout-block.hori {
  padding-top: 0;
  padding-bottom: 0;
}
label {
  display: inline-block;
  line-height: 1.42857;
  font-style: normal;
}
.ag-model-btn {
  font-family: 'Microsoft Yahei', Helvetica, sans-serif;
  min-width: 63px;
  height: 30px;
  padding: 0 10px;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 2px;
}
.ag-model-btn.blue {
  color: #fff;
  border: 1px solid #5c76cc;
  background-color: #5c76cc;
}
.ag-model-btn.gray {
  color: #333;
  background-color: #f4f5f7;
  border: 1px solid #d2d2d2;
}
.ag-model-btn.gray:active {
  border-color: #d2d2d2;
  background-color: #dddee0;
}
.ag-model-btn.gray:hover {
  border-color: #d2d2d2;
  background-color: #fff;
}
.ag-model-btn.blue:active {
  border-color: #5c76cc;
  background-color: #5c76cc;
}
.ag-model-btn.blue:hover {
  border-color: #5c76cc;
  background-color: #5c76cc;
}

.el-input-group {
  width: 350px;
}
.el-input-group__append,
.el-input-group__prepend {
  padding: 0 4px;
}
.seqcheck {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 10px;
}
.pass {
  background-color: #52cc8f;
}
.nopass {
  background-color: #e65c5c;
}
.search .row {
  margin: 20px 0;
  display: flex;
}
.search .row > label {
  font-size: 14px;
  font-weight: bold;
  /* margin-right: 20px; */
  width: 90px;
  text-align: right;
  flex: none;

  & + span {
    flex: none;
  }
}
.search .row > span {
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
  margin-right: 15px;
}
/deep/ .search .row > span.active {
  color: @linkColor;
}
/deep/ .search .el-radio__input {
  display: none;
}
/deep/ .search .el-checkbox__input {
  display: none;
}
/deep/ .search .el-radio {
  margin-right: 0;
}
/deep/ .search .el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}
/deep/ .search .el-checkbox-group .el-checkbox {
  margin-right: 8px;
}
section .title {
  font-weight: bold;
}
/* .el-checkbox-group .el-checkbox:first-child .el-checkbox__input {
    display: none;
} */
.el-input-group__append {
  padding: 0 20px;
}
.el-input-group__append button.el-button--primary {
  background: @linkColor;
  color: #fff;
}
.el-radio + .el-radio {
  margin-left: 10px;
}
.el-dialog__body {
  padding-bottom: 20px;
}
.reset {
  color: @linkColor;
  cursor: pointer;
}
.divider {
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background: #dcdfe6;
  position: relative;
}
.divider span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0 20px;
  background: #fff;
  cursor: pointer;
  color: @linkColor;
}
.divider i {
  font-size: 16px;
}
.spreadSearch {
  transform: scaleY(1);
  transition: all 0.5 linear;
}
.shrinkSearch {
  transform: scaleY(0);
  transition: all 0.5 linear;
}
.offline {
  color: #f00;
}
</style>